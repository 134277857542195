// Generated by Framer (06534cb)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, FormPlainTextInput, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withVariantAppearEffect } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import AnimatedLine from "./uAsrSsx9N";
const AnimatedLineFonts = getFonts(AnimatedLine);
const AnimatedLineWithVariantAppearEffect = withVariantAppearEffect(AnimatedLine);

const cycleOrder = ["kPoe1yBlq", "UFD0m92Zs"];

const serializationHash = "framer-o2u63"

const variantClassNames = {kPoe1yBlq: "framer-v-1wgo6u8", UFD0m92Zs: "framer-v-1f88m91"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.6, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableEnumMap = {"Phone Number": "tel", "Text Area": "textarea", Date: "date", Email: "email", Number: "number", Text: "text", Time: "time", URL: "url"}

const humanReadableVariantMap = {Desktop: "kPoe1yBlq", Phone: "UFD0m92Zs"}

const getProps = ({height, id, name1, placeholder, required, type, width, ...props}) => { return {...props, nLMeiUEBV: placeholder ?? props.nLMeiUEBV ?? "Your name *", pyyqcfx5Y: humanReadableEnumMap[type] ?? type ?? props.pyyqcfx5Y ?? "text", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kPoe1yBlq", VN6MRS_AH: required ?? props.VN6MRS_AH ?? true, X5hXpAjCS: name1 ?? props.X5hXpAjCS ?? "Name"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;type?: keyof typeof humanReadableEnumMap;name1?: string;placeholder?: string;required?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, pyyqcfx5Y, X5hXpAjCS, nLMeiUEBV, VN6MRS_AH, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kPoe1yBlq", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1wgo6u8", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"kPoe1yBlq"} ref={refBinding} style={{...style}} {...addPropertyOverrides({UFD0m92Zs: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><FormPlainTextInput className={"framer-7083pm"} inputName={X5hXpAjCS} layoutDependency={layoutDependency} layoutId={"jccFDfs5t"} placeholder={nLMeiUEBV} required={VN6MRS_AH} style={{"--framer-input-font-color": "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))", "--framer-input-icon-color": "rgb(153, 153, 153)", "--framer-input-placeholder-color": "rgb(153, 153, 153)"}} type={pyyqcfx5Y}/><ComponentViewportProvider height={1} width={componentViewport?.width || "100vw"} y={(((componentViewport?.y || 0) + (componentViewport?.height || 40)) - 0)}><SmartComponentScopedContainer className={"framer-19asd87-container"} layoutDependency={layoutDependency} layoutId={"HLYjCWL1u-container"} nodeId={"HLYjCWL1u"} rendersWithMotion scopeId={"TWOB0A5WT"}><AnimatedLineWithVariantAppearEffect __framer__animateOnce={false} __framer__obscuredVariantId={"KTHclA_ym"} __framer__threshold={0.5} __framer__variantAppearEffectEnabled __framer__visibleVariantId={"W8r8zwETw"} eV0W9xNCm={0.12} height={"100%"} id={"HLYjCWL1u"} L7khgg0dA={"rgb(0, 0, 0)"} layoutId={"HLYjCWL1u"} style={{width: "100%"}} variant={"W8r8zwETw"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-o2u63.framer-1cyqxa9, .framer-o2u63 .framer-1cyqxa9 { display: block; }", ".framer-o2u63.framer-1wgo6u8 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 40px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 562px; }", ".framer-o2u63 .framer-7083pm { --framer-input-focused-border-color: var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, #0a0a0a); --framer-input-focused-border-style: solid; --framer-input-focused-border-width: 0px 0px 1px 0px; --framer-input-font-family: \"Inter\"; --framer-input-font-letter-spacing: -0.04em; --framer-input-font-line-height: 1.2em; --framer-input-font-size: 18px; --framer-input-font-weight: 500; --framer-input-padding: 0px 0px 20px 0px; flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", ".framer-o2u63 .framer-19asd87-container { bottom: -1px; flex: none; height: auto; left: 0px; position: absolute; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-o2u63.framer-1wgo6u8 { gap: 0px; } .framer-o2u63.framer-1wgo6u8 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-o2u63.framer-1wgo6u8 > :first-child { margin-top: 0px; } .framer-o2u63.framer-1wgo6u8 > :last-child { margin-bottom: 0px; } }", ".framer-o2u63.framer-v-1f88m91.framer-1wgo6u8 { width: 390px; }", ".framer-o2u63.framer-v-1f88m91 .framer-7083pm { --framer-input-font-size: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 562
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UFD0m92Zs":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pyyqcfx5Y":"type","X5hXpAjCS":"name1","nLMeiUEBV":"placeholder","VN6MRS_AH":"required"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTWOB0A5WT: React.ComponentType<Props> = withCSS(Component, css, "framer-o2u63") as typeof Component;
export default FramerTWOB0A5WT;

FramerTWOB0A5WT.displayName = "Input";

FramerTWOB0A5WT.defaultProps = {height: 40, width: 562};

addPropertyControls(FramerTWOB0A5WT, {variant: {options: ["kPoe1yBlq", "UFD0m92Zs"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}, pyyqcfx5Y: {defaultValue: "text", options: ["text", "textarea", "email", "number", "tel", "url", "date", "time"], optionTitles: ["Text", "Text Area", "Email", "Number", "Phone Number", "URL", "Date", "Time"], title: "Type", type: ControlType.Enum}, X5hXpAjCS: {defaultValue: "Name", title: "Name", type: ControlType.String}, nLMeiUEBV: {defaultValue: "Your name *", title: "Placeholder", type: ControlType.String}, VN6MRS_AH: {defaultValue: true, title: "Required", type: ControlType.Boolean}} as any)

addFonts(FramerTWOB0A5WT, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}, ...AnimatedLineFonts], {supportsExplicitInterCodegen: true})